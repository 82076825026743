@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

:root {
  --red: #cc0202;
  --green: #119955;
  --white: #fafbfc;
  --light: #e1e2e3;
  --dark: #212121;
  --dark-transparent: #21212121;
  --grey: #767676;
  --dark-grey: #373737;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e1e2e3;
  background: var(--light);
}

::-webkit-scrollbar-thumb {
  background: #cc0202;
  background: var(--red);
}

body {
  font-family: 'Source Sans Pro', 'Roboto', 'arial', sans-serif;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 2px solid #e1e2e3;
  border: 2px solid var(--light);
  border-top-color: #cc0202;
  border-top-color: var(--red);
  -webkit-animation: spinner 0.6s linear infinite;
          animation: spinner 0.6s linear infinite;
}

@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.toast {
  display: flex;
  position: absolute;
  top: 55px;
  left: 10px;
  z-index: 3;
  min-height: 45px;
  overflow: hidden;
  width: 310px;
  background: #fafbfc;
  background: var(--white);
  border-radius: 3px;
  box-shadow: 0 0 5px -2px #212121;
  box-shadow: 0 0 5px -2px var(--dark);
}

.toast .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 45px;
  margin: auto;
}

.toast .icon img {
  width: 25px;
  height: 25px;
}

.toast .message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.toast .message p {
  color: #212121;
  color: var(--dark);
  font-family: 'Source Sans Pro', 'Roboto', 'arial', sans-serif;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 1.35;
}

.toast .close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 45px;
  margin: auto;
}

.toast .close-btn button {
  width: 100%;
  height: 100%;
  padding: 13px;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
}

.toast .close-btn button img {
  width: 100%;
  height: 100%;
}

.navbar-container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 45px;
  padding: 0 4%;
  background: #cc0202;
  background: var(--red);
  box-shadow: 0 1px 3px -1px #212121;
  box-shadow: 0 1px 3px -1px var(--dark);
  z-index: 2;
  position: absolute;
}

.navbar-container .logo {
  width: 45px;
  height: 45px;
  padding: 4px;
  margin-right: auto;
}

.navbar-container .logo img {
  width: 100%;
  height: 100%;
}

.navbar-container .add-animal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: none;
  padding: 1px;
  background: #fafbfc;
  background: var(--white);
  cursor: pointer;
  outline: none;
  position: relative;
  margin-left: 7px;
}

.navbar-container .add-animal img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.navbar-container .add-animal.active::before,
.navbar-container .add-animal.active::after {
  display: block;
  margin: 0;
  padding: 0;
  content: '';
  border-radius: 50%;
  border: 2px solid #fafbfc;
  border: 2px solid var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.navbar-container .add-animal.active::before {
  -webkit-animation: add-animal-button 1100ms ease infinite running;
          animation: add-animal-button 1100ms ease infinite running;
}

.navbar-container .add-animal.active::after {
  -webkit-animation: add-animal-button 1100ms ease 750ms infinite running;
          animation: add-animal-button 1100ms ease 750ms infinite running;
}

@-webkit-keyframes add-animal-button {
  0% {
    width: 0px;
    height: 0px;
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

@keyframes add-animal-button {
  0% {
    width: 0px;
    height: 0px;
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

.navbar-container .search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 7px;
  position: relative;
}

.navbar-container .search-container .search {
  display: flex;
  align-items: center;
  background: #fafbfc;
  background: var(--white);
  padding: 2px 5px;
  border-radius: 25px;
  transition: all 250ms ease;
}

.navbar-container .search-container .search .icon {
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.navbar-container .search-container .search .icon img {
  width: 100%;
  height: 100%;
}

.navbar-container .search-container .search .input-field input {
  width: 175px;
  background: #fafbfc;
  background: var(--white);
  padding: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-family: 'Source Sans Pro', 'Roboto', 'arial', sans-serif;
  color: #212121;
  color: var(--dark);
  transition: all 250ms ease;
}

.navbar-container .search-container .suggestions {
  position: absolute;
  top: 42px;
  width: 100%;
  background: #fafbfc;
  background: var(--white);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 4px -2px #212121;
  box-shadow: 0 0 4px -2px var(--dark);
}

.navbar-container .search-container .suggestions .sug {
  font-size: 14px;
  padding: 14px 22px;
  font-family: 'Source Sans Pro', 'Roboto', 'arial', sans-serif;
  color: #212121;
  color: var(--dark);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 250ms ease;
}

.navbar-container .search-container .suggestions .sug p {
  word-break: break-all;
}

.navbar-container .search-container .suggestions .sug:hover {
  color: #cc0202;
  color: var(--red);
  text-decoration: underline;
}

.navbar-container .user {
  display: flex;
  align-items: center;
}

.navbar-container .home-link,
.navbar-container .usage-link,
.navbar-container .dashboard-link,
.navbar-container .settings-link,
.navbar-container .logout-link,
.navbar-container .logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  background: #fafbfc;
  background: var(--white);
  cursor: pointer;
  margin-left: 7px;
  outline: none;
  position: relative;
}

.navbar-container .home-link img,
.navbar-container .usage-link img,
.navbar-container .dashboard-link img,
.navbar-container .settings-link img,
.navbar-container .logout-link img,
.navbar-container .logout-btn img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.navbar-container .home-link img,
.navbar-container .dashboard-link img,
.navbar-container .logout-btn img {
  border-radius: 0%;
  padding: 1px;
}

.navbar-container .usage-link .first-time {
  position: relative;
  width: 100%;
  height: 100%;
}

.navbar-container .usage-link .first-time:after {
  display: block;
  content: '';
  width: 36px;
  height: 36px;
  border: 2px solid #fafbfc;
  border: 2px solid var(--white);
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -9px;
  -webkit-animation: shrink-grow 500ms ease infinite running;
          animation: shrink-grow 500ms ease infinite running;
}

@-webkit-keyframes shrink-grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shrink-grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.navbar-container .usage-link .first-time span {
  display: block;
  width: 180px;
  position: absolute;
  top: 36px;
  left: -150px;
  text-align: center;
  background: #fafbfc;
  background: var(--white);
  padding: 6px;
  border-radius: 4px;
  color: #cc0202;
  color: var(--red);
  font-weight: 700;
  font-size: 13px;
  box-shadow: 0 0 4px #212121;
  box-shadow: 0 0 4px var(--dark);
}

.map-container {
  display: flex;
  padding-top: 45px;
}

.map-gl-container {
  padding-left: 0;
  transition: all 500ms ease;
}

.map-gl-container.side-open {
  padding-left: 300px;
}

.map-container .pin {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.pin-container .pin img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -50%;
}

.pin-container .show-type {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -30px;
  left: -15px;
  width: 44px;
  height: 20px;
  border-radius: 3px;
  background: #fafbfc;
  background: var(--white);
  box-shadow: 0 0 4px -2px #cc0202;
  box-shadow: 0 0 4px -2px var(--red);
}

.pin-container .show-type p {
  color: #cc0202;
  color: var(--red);
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 13.5px;
  font-weight: 700;
}

.map-container .sidebar-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  height: calc(100vh - 45px);
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 1px 2px 4px -2px #212121;
  box-shadow: 1px 2px 4px -2px var(--dark);
  z-index: 1;
  position: absolute;
  left: -300px;
  transition: all 500ms ease;
}

.map-container .sidebar-container.side-open {
  left: 0;
}

.sidebar-container .add-animal-container {
  height: 100%;
  padding: 20px 20px 20px 20px;
  overflow-y: auto;
}

.add-animal-container .error {
  font-size: 13px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  color: #cc0202;
  color: var(--red);
  padding: 4px 0;
}

.add-animal-container .header {
  text-align: center;
}

.add-animal-container .header h2 {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  color: #212121;
  color: var(--dark);
}

.add-animal-container .form-animal {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
}

.add-animal-container .form-animal .input-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.add-animal-container .form-animal .input-form label {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  color: #212121;
  color: var(--dark);
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
}

.add-animal-container .form-animal .input-form label .red {
  color: #cc0202;
  color: var(--red);
  cursor: help;
}

.add-animal-container .form-animal .input-form select {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif !important;
  font-weight: 700 !important;
  color: #212121;
  color: var(--dark);
  padding: 8px;
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
  outline: none;
  background: #fafbfc;
  background: var(--white);
  cursor: pointer;
  transition: all 250ms ease;
}

.add-animal-container .form-animal .input-form textarea {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif !important;
  letter-spacing: 0.25px;
  font-size: 15px;
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  max-height: 150px;
  padding: 10px;
  background-color: #fafbfc;
  background-color: var(--white);
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
  outline: none;
}

.add-animal-container .form-animal .input-form select:hover,
.add-animal-container .form-animal .input-form textarea:hover {
  border-color: #cc0202;
  border-color: var(--red);
}

.add-animal-container .add-file {
  width: 100%;
  height: 38px;
  overflow: hidden;
  position: relative;
}

.add-animal-container .add-file button {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #212121;
  color: var(--dark);
  font-size: 12px;
  cursor: pointer;
  background: #fafbfc;
  background: var(--white);
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
  outline: none;
}

.add-animal-container .add-file button img {
  height: 25px;
  margin-left: 10px;
}

.add-animal-container .add-file input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  outline: none;
}

.add-animal-container .preview-picture {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.add-animal-container .preview-picture button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background: transparent;
  z-index: 10;
  padding: 8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}

.add-animal-container .preview-picture button img {
  width: 100%;
}

.add-animal-container .preview-picture .picture img {
  width: 100%;
  height: 100%;
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
}

.add-animal-container .footer,
.display-animal-container .footer {
  display: flex;
}

.add-animal-container .footer button,
.display-animal-container .footer button {
  width: 90px;
  padding: 8px;
  background: #fafbfc;
  background: var(--white);
  box-shadow: 0 0 4px -3px #767676;
  box-shadow: 0 0 4px -3px var(--grey);
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #212121;
  color: var(--dark);
  font-size: 11px;
  cursor: pointer;
  outline: none;
  transition: all 250ms ease;
}

.add-animal-container .footer button.save,
.display-animal-container .footer button.close {
  margin-right: auto;
}

.add-animal-container .footer button.save:hover {
  border-color: #119955;
  border-color: var(--green);
  color: #119955;
  color: var(--green);
}

.add-animal-container .footer button.cancel:hover,
.display-animal-container .footer button.report:hover {
  border-color: #cc0202;
  border-color: var(--red);
  color: #cc0202;
  color: var(--red);
}

.sidebar-container .display-animal-container {
  height: 100%;
  padding: 20px 20px 20px 20px;
  overflow-y: auto;
}

.display-animal-container .animal-type {
  text-align: center;
  margin-bottom: 15px;
}

.display-animal-container .animal-type h3 {
  color: #cc0202;
  color: var(--red);
  font-size: 24px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
}

.display-animal-container .animal-picture {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.display-animal-container .animal-picture img {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #cc0202;
  border: 1px solid var(--red);
}

.display-animal-container .animal-description {
  width: 100%;
  overflow: hidden;
  margin: 25px 0;
}

.display-animal-container .animal-description h4 {
  color: #cc0202;
  color: var(--red);
  font-size: 18px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  margin: 5px 0;
}

.display-animal-container .animal-description p {
  width: 100%;
  word-break: break-all;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 15px;
  color: #212121;
  color: var(--dark);
  letter-spacing: 0.25px;
  line-height: 1.5;
}

.display-animal-container .animal-description p.date {
  font-size: 12px;
  margin-bottom: 5px;
}

.display-animal-container .report-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  height: 0px;
  overflow: hidden;
  transition: all 200ms ease;
}

.display-animal-container .report-container.active {
  height: auto;
}

.display-animal-container .report-container h4 {
  color: #cc0202;
  color: var(--red);
  font-size: 13px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  margin: 5px 0;
}

.display-animal-container .report-container textarea {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif !important;
  letter-spacing: 0.25px;
  font-size: 15px;
  min-width: 100%;
  max-width: 100%;
  min-height: 70px;
  max-height: 70px;
  resize: none;
  padding: 10px;
  background-color: #fafbfc;
  background-color: var(--white);
  border: 1px solid #767676;
  border: 1px solid var(--grey);
  border-radius: 3px;
  outline: none;
  margin-bottom: 7px;
}

.display-animal-container .report-container textarea:hover {
  border-color: #cc0202;
  border-color: var(--red);
}

.display-animal-container .report-container .report-buttons {
  margin-top: 10px;
}

.display-animal-container .report-container button {
  padding: 2px;
  background: transparent;
  box-shadow: none;
  border: none;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #212121;
  color: var(--dark);
  font-size: 10px;
  cursor: pointer;
  outline: none;
  transition: all 250ms ease;
}

.display-animal-container .report-container button.submit {
  margin-right: 15px;
  color: #119955;
  color: var(--green);
}

.display-animal-container .report-container button.cancel {
  color: #cc0202;
  color: var(--red);
}

.display-animal-container .report-container p.error {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 13px;
  color: #cc0202;
  color: var(--red);
}

.display-animal-container .report-container p.sent {
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 13px;
  color: #119955;
  color: var(--green);
}

@media only screen and (max-width: 640px) {
  .navbar-container .search-container {
    transition: all 250ms ease;
  }

  .navbar-container .search-container .search {
    padding: 3px 3px;
    cursor: pointer;
  }

  .navbar-container .search-container .search .input-field input {
    width: 0;
    padding: 0;
  }

  .navbar-container .search-container .search div:last-child {
    max-width: 0 !important;
    max-height: 0 !important;
    overflow: hidden;
  }

  .navbar-container .search-container .suggestions {
    display: none;
  }

  .navbar-container .search-container.active {
    flex: 1 1;
  }

  .navbar-container .search-container.active .search {
    padding: 2px 6px;
    cursor: pointer;
  }

  .navbar-container .search-container.active .search .input-field {
    flex: 1 1;
    padding: 5px;
  }

  .navbar-container .search-container .search .input-field {
    width: 0;
    padding: 0px;
    transition: all 250ms ease;
  }

  .navbar-container .search-container .search .input-field input {
    width: 100%;
  }

  .navbar-container .search-container.active .search div:last-child {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  .navbar-container .search-container.active .suggestions {
    display: block;
  }
}

/* the login page */

.login-container {
  display: flex;
  min-height: 100vh;
  overflow: auto;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #cc0202;
  background: var(--red);
}

.login-container a {
  text-decoration: none;
}

.login-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  text-align: center;
  margin: 10px 0;
}

.login-container .header .logo {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 180px;
}

.login-container .header .logo img {
  width: 100%;
}

.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  width: 90%;
  margin-top: 20px;
}

.login-container form .error {
  color: #e1e2e3;
  color: var(--light);
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  left: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.login-container form .input {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.login-container form .input label {
  color: #e1e2e3;
  color: var(--light);
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  left: 10px;
}

.login-container form .input input {
  width: 100%;
  height: 38px;
  background: #fafbfc;
  background: var(--white);
  border: 1px solid #fafbfc;
  border: 1px solid var(--white);
  border-radius: 18px;
  outline: none;
  padding: 0 15px;
  margin-top: 5px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #212121;
  color: var(--dark);
}

.login-container form button {
  width: 140px;
  height: 38px;
  background: #fafbfc;
  background: var(--white);
  border: 1px solid #e1e2e3;
  border: 1px solid var(--light);
  border-radius: 18px;
  outline: none;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #cc0202;
  color: var(--red);
  text-transform: uppercase;
  cursor: pointer;
}

.login-container form .input input,
.login-container form button {
  box-shadow: 0 4px 9px -3px #212121;
  box-shadow: 0 4px 9px -3px var(--dark);
  transition: all 250ms ease;
}

.login-container form .input input:hover,
.login-container form .input input:focus,
.login-container form button:hover {
  box-shadow: none;
}

.login-container form .input .eye-btn {
  display: block;
  width: 32px;
  height: 32px;
  padding: 5px;
  position: relative;
  top: calc(0px - 35px);
  left: calc(100% - 36px);
  box-shadow: none;
}

.login-container form .buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-container form .buttons .home-link {
  font-size: 14px;
  font-family: 'Source Sans Pro', 'Roboto';
  text-transform: uppercase;
  font-weight: 700;
  color: #fafbfc;
  color: var(--white);
}

/* the not-found page */

.not-found-container {
  background: #cc0202;
  background: var(--red);
  display: flex;
  min-height: 100vh;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found-container .header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.not-found-container .header .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.not-found-container .header .logo img {
  width: 100%;
}

.not-found-container .header h3 {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  color: #e1e2e3;
  color: var(--light);
}

.not-found-container .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 350px;
  width: 90%;
  margin-bottom: 30px;
}

.not-found-container .main .not-found {
  width: 150px;
  margin-bottom: 15px;
}

.not-found-container .main .not-found img {
  width: 100%;
}

.not-found-container .main h4 {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  color: #e1e2e3;
  color: var(--light);
  text-transform: uppercase;
  margin-bottom: 5px;
}

.not-found-container .main p {
  color: #e1e2e3;
  color: var(--light);
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  margin-bottom: 15px;
}

.not-found-container .main a {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  color: #e1e2e3;
  color: var(--light);
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  transition: all 250ms ease;
}

.not-found-container .main a:hover {
  color: #767676;
  color: var(--grey);
}

/* the admin page */

.admin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.admin-container .animal-card-details-warrper {
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  position: fixed;
  z-index: 2;
  top: 45px;
  overflow: auto;
  background: #212121bb;
  -webkit-animation: fade-in 450ms ease forwards running;
          animation: fade-in 450ms ease forwards running;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.admin-container .animal-card-details-warrper .animal-card-details {
  display: flex;
  position: relative;
  max-width: 90%;
  width: 950px;
  height: 450px;
  margin: auto;
  background: #fafbfc;
  background: var(--white);
  box-shadow: 0 0 4px 1px #212121;
  border-left: 1px solid #21212121;
  border-left: 1px solid var(--dark-transparent);
  -webkit-animation: scale-in 250ms ease forwards running;
          animation: scale-in 250ms ease forwards running;
}

@-webkit-keyframes scale-in {
  0% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

.admin-container .animal-card-details-warrper .animal-card-details .picture {
  display: flex;
  width: 50%;
  height: 100%;
  padding: 25px;
  align-items: center;
  justify-content: center;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .picture
  img {
  width: 100%;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-left: 1px solid #21212121;
  border-left: 1px solid var(--dark-transparent);
  overflow-y: auto;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head {
  display: flex;
  border-bottom: 1px solid #21212121;
  border-bottom: 1px solid var(--dark-transparent);
  padding: 15px;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fafbfc;
  background: var(--white);
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .icon {
  width: 25px;
  height: 25px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .icon
  img {
  width: 100%;
  height: 100%;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .type {
  align-self: flex-end;
  color: #cc0202;
  color: var(--red);
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 5px;
  font-size: 18px;
  line-height: 1;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .created-at {
  text-transform: uppercase;
  font-size: 11px;
  padding: 6px 10px;
  background: #e6717170;
  color: #cc0202;
  color: var(--red);
  border-radius: 20px;
  margin-left: auto;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .delete-button,
.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .close-button {
  width: 28px;
  height: 28px;
  border: none;
  padding: 7px;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
  border-radius: 14px;
  background-color: transparent;
  transition: all 250ms ease;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .close-button {
  padding: 8px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .delete-button:hover,
.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .close-button:hover {
  background: #21212121;
  background: var(--dark-transparent);
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .delete-button
  svg,
.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .head
  .close-button
  svg {
  fill: #cc0202;
  fill: var(--red);
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .description {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 1px solid #21212121;
  border-bottom: 1px solid var(--dark-transparent);
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .description
  .title {
  font-size: 15px;
  color: #cc0202;
  color: var(--red);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .animal-informations
  .description
  .content {
  font-size: 16px;
  color: #373737;
  color: var(--dark-grey);
  word-break: break-all;
}

.admin-container .reports {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.animal-card-details-warrper .reports .title {
  display: flex;
  font-size: 15px;
  color: #cc0202;
  color: var(--red);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
  align-items: center;
}

.animal-card-details .reports .title .reports-count {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #373737;
  color: var(--dark-grey);
  margin-left: auto;
  margin-right: 20px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .reports
  .title
  .reports-count
  .no-report {
  opacity: 0.7;
  color: #cc0202;
  color: var(--red);
  font-size: 12px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .reports
  .report-list {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.admin-container
  .animal-card-details-warrper
  .animal-card-details
  .reports
  .report-list
  .report {
  width: 100%;
  border: 1px solid #21212121;
  border: 1px solid var(--dark-transparent);
  padding: 10px;
  color: #373737;
  color: var(--dark-grey);
  border-radius: 3px;
  margin-bottom: 10px;
}

.admin-container .dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 45px);
  margin-top: 45px;
  padding: 0 25px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fafbfc;
  background: var(--white);
}

.admin-container .dashboard .admin-firlers-bar {
  display: flex;
  align-items: center;
  background: #fafbfc;
  background: var(--white);
  border: 1px solid #21212121;
  border: 1px solid var(--dark-transparent);
  box-shadow: 0 0px 2px #2121212b;
  margin: 10px 0;
}

.admin-container .dashboard .admin-firlers-bar .title {
  margin-left: 15px;
}

.admin-container .dashboard .admin-firlers-bar .title span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #cc0202;
  color: var(--red);
}

.admin-container .dashboard .admin-firlers-bar .filters {
  display: flex;
  margin-left: auto;
}

.admin-container .dashboard .admin-firlers-bar .filters .by-date {
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 10px;
  border-left: 1px solid #21212121;
  border-left: 1px solid var(--dark-transparent);
  border-right: 1px solid #21212121;
  border-right: 1px solid var(--dark-transparent);
  margin-right: 10px;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .timetable-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .timetable-icon
  svg {
  width: 100%;
  height: 100%;
  fill: #cc0202;
  fill: var(--red);
}

.admin-container .dashboard .admin-firlers-bar .filters .by-date .options {
  width: 150px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
  color: #212121;
  color: var(--dark);
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .options
  > div
  > div {
  border: none;
  box-shadow: none;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .options
  > div
  > div:nth-child(1),
.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .options
  > div
  > div:nth-child(2) {
  background: transparent;
  background-color: transparent;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-date
  .options
  > div
  > div:nth-child(3) {
  box-shadow: 0 0px 2px #2121212b;
}

.admin-container .dashboard .admin-firlers-bar .filters .by-type {
  display: flex;
  align-items: center;
}

.admin-container .dashboard .admin-firlers-bar .filters .by-type .sort-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-type
  .sort-icon
  svg {
  width: 100%;
  height: 100%;
  fill: #cc0202;
  fill: var(--red);
}

.admin-container .dashboard .admin-firlers-bar .filters .by-type .options {
  width: 150px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
  color: #212121;
  color: var(--dark);
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-type
  .options
  > div
  > div {
  border: none;
  box-shadow: none;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-type
  .options
  > div
  > div:nth-child(1),
.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-type
  .options
  > div
  > div:nth-child(2) {
  background: transparent;
  background-color: transparent;
}

.admin-container
  .dashboard
  .admin-firlers-bar
  .filters
  .by-type
  .options
  > div
  > div:nth-child(3) {
  box-shadow: 0 0px 2px #2121212b;
}

.admin-container .dashboard .animals-list {
  border: 1px solid #21212121;
  border: 1px solid var(--dark-transparent);
  box-shadow: 0 0px 2px #2121212b;
}

.admin-container .dashboard .animals-list .no-data {
  text-align: center;
  padding: 20px 0px;
  font-weight: 700;
  color: #373737;
  color: var(--dark-grey);
  text-transform: uppercase;
  font-size: 14px;
}

.admin-container .dashboard .animals-list .header-card {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  overflow: hidden;
  background: #fafbfc;
  background: var(--white);
  border-bottom: 1px solid #21212121;
  border-bottom: 1px solid var(--dark-transparent);
}

.admin-container .dashboard .animals-list .header-card > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-container .dashboard .animals-list .header-card > div span {
  font-size: 12px;
  font-weight: 700;
  color: #cc0202;
  color: var(--red);
  text-transform: uppercase;
}

.admin-container .dashboard .animals-list .header-card .animal-type {
  width: 70px;
}

.admin-container .dashboard .animals-list .header-card .animal-picture {
  width: 80px;
}

.admin-container .dashboard .animals-list .header-card .animal-description {
  width: calc(100% - (70px + 80px + 90px + 120px + 75px));
}

.admin-container .dashboard .animals-list .header-card .animal-reports {
  width: 90px;
}

.admin-container .dashboard .animals-list .header-card .animal-created-at {
  width: 120px;
}

.admin-container .dashboard .animals-list .header-card .animal-buttons {
  width: 75px;
}

.admin-container .dashboard .animals-list .animal-card {
  display: flex;
  flex-wrap: wrap;
  min-height: 80px;
  overflow: hidden;
  background: #fafbfc;
  background: var(--white);
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #21212121;
  border-bottom: 1px solid var(--dark-transparent);
  opacity: 0.75;
  transition: all 200ms ease;
}

.admin-container .dashboard .animals-list .animal-card:hover {
  box-shadow: 0 0px 7px #2121213b;
  opacity: 1;
  z-index: 0;
}

.admin-container .dashboard .animals-list .animal-card:before {
  display: block;
  content: '';
  width: 4px;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 200ms ease;
}

.admin-container .dashboard .animals-list .animal-card:hover:before {
  background: #cc0202;
  background: var(--red);
}

.admin-container .dashboard .animals-list .animal-card:last-child {
  border: none;
}

.admin-container .dashboard .animals-list .animal-card .animal-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 3px;
}

.admin-container .dashboard .animals-list .animal-card .animal-type img {
  width: 20px;
  height: 20px;
}

.admin-container .dashboard .animals-list .animal-card .animal-type b {
  color: #373737;
  color: var(--dark-grey);
  font-size: 12px;
  margin: 5px;
}

.admin-container .dashboard .animals-list .animal-card .animal-type .time {
  width: 70px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #373737;
  color: var(--dark-grey);
  margin: auto;
}

.admin-container .dashboard .animals-list .animal-card .animal-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
}

.admin-container .dashboard .animals-list .animal-card .animal-picture img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.admin-container .dashboard .animals-list .animal-card .animal-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border-radius: 3px;
}

.admin-container .dashboard .animals-list .animal-card .animal-description b {
  font-size: 11px;
  text-transform: uppercase;
  color: #cc0202;
  color: var(--red);
}

.admin-container .dashboard .animals-list .animal-card .animal-reports {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
}

.admin-container .dashboard .animals-list .animal-card .animal-reports span {
  display: flex;
  border-radius: 15px;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
}

.admin-container .dashboard .animals-list .animal-card .animal-reports .title {
  color: #212121;
  color: var(--dark);
}

.admin-container .dashboard .animals-list .animal-card .animal-reports .count {
  margin-left: 5px;
  color: #cc0202;
  color: var(--red);
}

.admin-container .dashboard .animals-list .animal-card .animal-created-at {
  display: flex;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border-radius: 3px;
  width: 120px;
}

.admin-container .dashboard .animals-list .animal-card .animal-created-at span {
  padding: 7px 10px;
  background: #21212121;
  background: var(--dark-transparent);
  color: #212121;
  color: var(--dark);
  border-radius: 15px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-reports
  .no-report {
  display: flex;
  font-size: 14px;
  text-align: center;
  margin: auto;
}

.admin-container .dashboard .animals-list .animal-card .animal-description p,
.admin-container .dashboard .animals-list .animal-card .animal-reports .report {
  display: flex;
  font-size: 14px;
  width: 100%;
  word-break: break-word;
  margin: 10px 0;
  line-height: 1.4;
}

.admin-container .dashboard .animals-list .animal-card .animal-buttons {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto;
  padding: 10px 20px;
}

.admin-container .dashboard .animals-list .animal-card .animal-buttons button {
  display: flex;
  align-items: center;
  height: 15px;
  padding: 17px 10px;
  border-radius: 20px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button
  img,
.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button
  svg {
  width: 15px;
  height: 15px;
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button.delete {
  background: #21212121;
  background: var(--dark-transparent);
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button.delete
  svg {
  fill: #212121;
  fill: var(--dark);
  transition: all 200ms ease-in;
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button:hover {
  transform: rotate(15deg);
  background: #cc020220;
  box-shadow: 0px 0px 4px -2px #cc0202;
  box-shadow: 0px 0px 4px -2px var(--red);
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button:hover
  svg {
  fill: #cc0202;
  fill: var(--red);
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button
  span {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Source Sans Pro', 'Roboto', Arial, sans-serif;
  color: #373737;
  color: var(--dark-grey);
  text-align: center;
  margin: auto;
  transition: color 250ms ease;
}

.admin-container
  .dashboard
  .animals-list
  .animal-card
  .animal-buttons
  button:hover
  span {
  color: #cc0202;
  color: var(--red);
}

.admin-container .dashboard .animals-list .animal-card .sm-screen {
  display: none;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  background-color: var(--white);
  height: 100vh;
}

.privacy-policy .privacy-content {
  position: relative;
  top: 45px;
  width: 100%;
  height: calc(100vh - 45px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 75px 10%;
}

.privacy-policy .privacy-content .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 75px;
  max-width: 750px;
}

.privacy-policy .privacy-content .header .logo {
  width: 190px;
  height: 190px;
  background: #cc0202;
  background: var(--red);
  border-radius: 50%;
  padding: 19px;
}

.privacy-policy .privacy-content .header .logo img {
  width: 100%;
}

.privacy-policy .privacy-content h1,
.privacy-policy .privacy-content h2,
.privacy-policy .privacy-content h3 {
  color: #cc0202;
  color: var(--red);
  margin-top: 15px;
  margin-bottom: 15px;
}

.privacy-policy .privacy-content strong {
  color: #cc0202;
  color: var(--red);
  margin-bottom: 10px;
}

.privacy-policy .privacy-content a {
  color: #cc0202;
  color: var(--red);
}

.privacy-policy .privacy-content p {
  color: #212121;
  color: var(--dark);
  line-height: 1.4;
  font-size: 16px;
  line-height: 1.7;
}

.privacy-policy .privacy-content li {
  margin-left: 25px;
  line-height: 1.7;
}

.how-to-use {
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  background-color: var(--white);
  height: 100vh;
}

.how-to-use .usage-content {
  height: calc(100vh - 45px);
  position: relative;
  top: 45px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 75px 10%;
}

.how-to-use .usage-content .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 75px;
  max-width: 750px;
}

.how-to-use .usage-content .header .logo {
  width: 190px;
  height: 190px;
  background: #cc0202;
  background: var(--red);
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 20px;
}

.how-to-use .usage-content .header .logo img {
  width: 100%;
}

.how-to-use .usage-content h1,
.how-to-use .usage-content h2,
.how-to-use .usage-content h3 {
  color: #cc0202;
  color: var(--red);
  margin-top: 15px;
  margin-bottom: 15px;
}

.how-to-use .usage-content h1,
.how-to-use .usage-content > p {
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.how-to-use .usage-content .options {
  margin-top: 50px;
}

.how-to-use .usage-content .options .option {
  margin-top: 50px;
}

.how-to-use .usage-content .options .option .title {
  display: flex;
  align-items: center;
}

.how-to-use .usage-content .options .option .title img {
  width: 20px;
  height: 20px;
}

.how-to-use .usage-content .options .option .title h2 {
  margin-left: 15px;
}

.how-to-use .usage-content .options .option ul li {
  margin-top: 35px;
}

.how-to-use .usage-content .options .option h4 {
  font-size: 21px;
  margin-bottom: 10px;
  color: #cc0202;
  color: var(--red);
}

.how-to-use .usage-content .options .option p {
  line-height: 1.7;
  text-indent: 15px;
}

.how-to-use .usage-content .options .option .icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

@media only screen and (max-width: 740px) {
  .admin-container .dashboard {
    padding: 0 10px;
  }

  .admin-container .dashboard .animals-list {
    border: none;
    box-shadow: none;
  }

  .admin-container .dashboard .animals-list .animal-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0;
    margin-bottom: 15px;
    border: 1px solid #21212121;
    border: 1px solid var(--dark-transparent);
    box-shadow: 0 0px 2px #2121212b;
  }

  .admin-container .dashboard .animals-list .animal-card .lg-screen,
  .admin-container .dashboard .animals-list .animal-card:hover::before {
    display: none;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-type {
    width: calc(100% - 20px);
  }

  .admin-container .dashboard .animals-list .animal-card .animal-type .time {
    width: auto;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 220px;
    max-height: 280px;
    overflow: hidden;
    border: 1px solid #21212121;
    border: 1px solid var(--dark-transparent);
    border-radius: 0;
    border-left: none;
    border-right: none;
    width: 100%;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-description {
    width: calc(100% - 20px);
    margin: 25px 0;
  }

  .admin-container .dashboard .animals-list .animal-card .sm-screen {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-reports {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }

  .admin-container
    .dashboard
    .animals-list
    .animal-card
    .animal-reports
    .count {
    padding: 7px 10px;
    background: #21212121;
    background: var(--dark-transparent);
    color: #cc0202;
    color: var(--red);
    border-radius: 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    box-shadow: 0 0px 1px #2121212b;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-created-at {
    padding: 0;
    width: auto;
    margin-left: 5px;
  }

  .admin-container
    .dashboard
    .animals-list
    .animal-card
    .animal-created-at.time {
    box-shadow: 0 0px 1px #2121212b;
  }

  .admin-container .dashboard .animals-list .animal-card .animal-buttons {
    padding: 0;
    width: auto;
    margin-right: 10px;
    margin-left: auto;
  }

  .admin-container
    .dashboard
    .animals-list
    .animal-card
    .animal-buttons
    button.delete {
    box-shadow: 0 0px 1px #2121212b;
  }

  .privacy-policy .privacy-content {
    padding: 25px;
  }

  .admin-container .dashboard .admin-firlers-bar {
    flex-direction: column;
  }

  .admin-container .dashboard .admin-firlers-bar .title {
    margin-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
  }

  .admin-container .dashboard .admin-firlers-bar .filters {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .admin-container .dashboard .admin-firlers-bar .filters .by-date {
    width: 100%;
    margin: 0;
    border: none;
    padding: 2px 15px;
    border-top: 1px solid #21212121;
    border-top: 1px solid var(--dark-transparent);
  }

  .admin-container .dashboard .admin-firlers-bar .filters .by-date .options {
    width: 100%;
  }

  .admin-container .dashboard .admin-firlers-bar .filters .by-type {
    width: 100%;
    margin: 0;
    border: none;
    padding: 2px 15px;
    border-top: 1px solid #21212121;
    border-top: 1px solid var(--dark-transparent);
  }

  .admin-container .dashboard .admin-firlers-bar .filters .by-type .options {
    width: 100%;
  }

  .admin-container .dashboard .animals-list .header-card {
    display: none;
  }

  .admin-container .dashboard .animals-list .animal-card {
    opacity: 1;
  }

  .admin-container .animal-card-details-warrper .animal-card-details {
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 45px);
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .admin-container
    .animal-card-details-warrper
    .animal-card-details
    .animal-informations {
    width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    overflow: visible;
    overflow: initial;
    background: #fafbfc;
    background: var(--white);
  }

  .admin-container
    .animal-card-details-warrper
    .animal-card-details
    .animal-informations
    .head {
    border-top: 1px solid #21212121;
    border-top: 1px solid var(--dark-transparent);
    padding: 15px 5px;
  }

  .admin-container .animal-card-details-warrper .animal-card-details .picture {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 420px;
    padding: 25px;
    overflow: hidden;
  }

  .admin-container
    .animal-card-details-warrper
    .animal-card-details
    .animal-informations
    .description {
    padding: 15px 5px;
  }

  .admin-container .reports {
    padding-left: 5px;
  }

  .admin-container
    .animal-card-details-warrper
    .animal-card-details
    .reports
    .report-list {
    padding-right: 5px;
  }
}

